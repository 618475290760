import { useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextareaAutosize from "react-textarea-autosize";
import { MentionsInput, Mention } from 'react-mentions';

import FormContainer from "components/FormContainer";
import Button from "components/Button";

import { generateContent } from "actions/ai.actions";

function AIForm({aiReducer, generateContent}) {

    const title = 'Vertex AI'
    const [isRAG, setIsRAG] = useState(false)
    const [request, setRequest] = useState(null)
    const [task, setTask] = useState('')
    const [statement, setStatement] = useState(null)
    const [situation, setSituation] = useState(null)
    const [complications, setComplications] = useState(null)
    const [temperature, setTemperature] = useState(1.0);

    console.log(task)

    const handleTaskChange = (e) => {
        setTask(e.target.value)
    }

    const handleStementChange = (e) => {
        setStatement(e.target.value)
    }

    const handleSituationChange = (e) => {
        setSituation(e.target.value)
    }

    const handleComplicaionsChange = (e) => {
        setComplications(e.target.value)
    }

    const handleRAGChange = () => {
        setIsRAG(!isRAG)
    }

    const handleTemperatureChange = (e) => {
        setTemperature(e.target.value)
    }

    const file_href="https://storage.googleapis.com/solverint-develop-assets/test/tmp/chart_data_table1.png?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=sa-api%40foren-develop.iam.gserviceaccount.com%2F20240924%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240924T194434Z&X-Goog-Expires=604800&X-Goog-SignedHeaders=host&X-Goog-Signature=08ff7cb854ac452ceb2bace7433d53e9c4db9c20c302adeb3d4fdcb84eb20b68f8d892b4d7fed136b624ca1d194f5ec165693802a4810d3cc55f5f0be20c7e3c1f176cdf7c69fefe7d1fd110f945e8f0bea7699238f6c4cf1e0bb8c3caea15e05bd9e91008ed2d162bf948e9068ee902f7328c616ef21f4d9c1d43c0463ad1990c9597fb2179c7b603ba021fccaddcff1f541b439e1312f7aa023d65aca3698fac6ab75882124803075192dce5ef0bf33e16d6cca046d8a40dd57928004c70ed86e54ab8e02312c7f3624feced45f0bb4e09f2903142ac1dcddc383bd2505d16b6ae5b86c453ff35b1293948ccf92715f819d41e568a8f853f4ccdd120682e08"


    const handleClick = () => {

        // let q = question.replace('$statement', 'STATEMENT').replace('$situation', 'SITUATION').replace('$complications','COMPLICATIONS')
        let t = task
        //.replace('@[$statement](statement)', 'STATEMENT')
        //.replace('@[$situation](situation)', 'SITUATION')
        //.replace('@[$complications](complications)','COMPLICATIONS')
        //.replace('@[$chart_data_table1.png](file)','FILE')
        // if(text) {
        //     generateContent(`${prompt}.\nTEXT: ${text}`)
        // } else {
        //     generateContent(prompt)
        // }
        if (statement && task.includes('$statement')) {
            t = t.replace('@[$statement](statement)', 'STATEMENT')
            t += `\nSTATEMENT: ${statement}`
        } 
        if (situation && task.includes('$situation')) {
            t = t.replace('@[$situation](situation)', 'SITUATION')
            t += `\nSITUATION: ${situation}`
        } 
        if (complications && task.includes('$complications')) {
            t = t.replace('@[$complications](complications)','COMPLICATIONS')
            t += `\nCOMPLICATIONS: ${complications}`
        }
        if (task.includes('chart_data_table1.png')) {
            t = t.replace('@[$file (chart_data_table1.png)](file)','FILE')
            t += `\nFILE: ${file_href}`
        } 

        setRequest(t)
        generateContent(t, isRAG)
    }
    /*
    Based on $statement, $situation and $complications how many shops has Tchibo?

    Tchibo is a German chain of coffee retailers and cafés known for its range of non-coffee products that change weekly.[3] The latter includes: clothing, furniture, household items, electronics and electrical appliances. In Germany, Tchibo's slogan is "Every week a new world" (German: Jede Woche eine neue Welt).[4] Tchibo has further expanded its product range to sell services such as travel, insurance, and mobile-phone contracts.

    With over 1,000 shops, Tchibo is one of Germany's largest retail chains. The company is headquartered in Hamburg. Tchibo's coffee is sold in supermarkets and other smaller stores in the United States, Canada, Czech Republic, Saudi Arabia, Slovakia, Slovenia, Bulgaria, Romania, Turkey, Hungary, Ukraine, Syria, Jordan, Russia,[5] United Arab Emirates, Poland, Ireland, United Kingdom, Switzerland, Liechtenstein, and Lebanon. It is also sold online.

    Tchibo has no shops in Russia anymore.

    */

// CONTEXT:
// (RAG Documents)

// QUESTION:
// (users question)

// INSTRUCTIONS:
// Answer the users QUESTION using the CONTEXT text above.
// Keep your answer ground in the facts of the CONTEXT.
// If the CONTEXT doesn’t contain the facts to answer the QUESTION return {'Nothing found! Please try without RAG!'}

    let suggestions = [
        {
            id: 'statement',
            display: '$statement'
        },
        {
            id: 'situation',
            display: '$situation'
        },
        {
            id: 'complications',
            display: '$complications'
        },
        {
            id: 'file',
            display: '$file (chart_data_table1.png)'
        }
    ]

    return (
        <div>
            <FormContainer
                guide={false}
                title={title}>
                <div>
                    https://cloud.google.com/vertex-ai/docs/generative-ai/learn/introduction-prompt-design
                    https://cloud.google.com/vertex-ai/docs/generative-ai/learn/prompt-samples
                    <br/>
                    Wie viel verdient doch Tom Hanks (try temp=0.8 and 1.0)?<br/>
                    Wo wohnt Tom Hanks?<br/>
                    Was kann Tom Hanks jedem empfehlen?<br/>
                    Wie viele Kinder hat Tom Hanks?<br/>
                    Wie viel verdient doch Tom Hanks? Plese take into account $complications (convert dollar to euro). Answer in russian<br/>
                    Based on $statement, $situation and $complications propose anything <br/>
                    How to increase income of Tom Hanks?
                    Tom Hanks does not earn enought modey
                    Tom Hanks cannot make movies
                    Give me all information about Tom Hanks?
                </div>
                <div>
                    Task
                    <div className="mt-1">
                        <MentionsInput 
                            value={task} 
                            className="h-20 shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            onChange={handleTaskChange}>
                            <Mention
                                trigger="$"
                                data={suggestions}
                            />
                        </MentionsInput>
                    </div>
                </div>
                <div>
                    <pre>{prompt}</pre>
                    <pre>----REQUEST----</pre>
                    <pre>{request}</pre>
                    <pre>----RESPONSE---</pre>
                    <pre>{aiReducer?.response}</pre>
                </div>
                <Button onClick={handleClick}>Submit</Button>
                <div>
                    $statement
                    <div className="mt-1">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="statement"
                            id="statement"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="$statement"
                            value={statement}
                            onChange={handleStementChange}
                        />
                    </div>
                </div>
                <div>
                    $situation
                    <div className="mt-1">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="situation"
                            id="situation"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="$situation"
                            value={situation}
                            onChange={handleSituationChange}
                        />
                    </div>
                    $complications
                    <div className="mt-1">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="complications"
                            id="complications"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="$complications"
                            value={complications}
                            onChange={handleComplicaionsChange}
                        />
                    </div>
                    <a href={file_href}>
                            chart_data_table1.png
                    </a>
                    <div>
                        <input 
                            type="checkbox" 
                            id="rag" 
                            name="rag" 
                            value={isRAG}
                            onChange={handleRAGChange} />
                        <label for="rag"> RAG</label>
                    </div>
                    <div>
                        <input 
                            id="temperature"
                            type="range" 
                            min="0" 
                            max="2" 
                            step="0.1"
                            value={temperature}
                            onChange={handleTemperatureChange} 
                            />
                        <label for="temperature">Temperature: {temperature}</label>
                    </div>
                </div>
            </FormContainer>
        </div>
    );
}

AIForm.propTypes = {
    generateContent: PropTypes.func.isRequired
  };
  
const mapStateToProps = (state) => ({
    aiReducer: state.aiReducer
});

export default connect(mapStateToProps, { generateContent })(AIForm);