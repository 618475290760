import React, { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function TooltipMobile({ content, width, children, linkRef, link, mobileClassName, className, hoverActive, inline }) {
    const [active, setActive] = useState(false);
    const mobileBreakpoint = 'md:hidden'
    const webBreakpoint = 'md:block'

    const toggleTip = () => {
        const current = active;
        setActive(!current);
    };

    const handleMouseEnter = () => {
        if (hoverActive) setActive(true);
    };

    const handleMouseLeave = () => {
        if (hoverActive) setActive(false);
    };

    const backgroundColor = "bg-primary-100"
    const divClass = "text-sm leading-snug left-9 z-40 p-2 "
    const iconClasses = "w-4 h-4 inline text-primary-600"

    return (
        <div className={`${className} flex justify-end cursor-pointer`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {/* Mobile */}
            <div
                id="tooltipIconMobile"
                onClick={toggleTip}
                className={`${mobileBreakpoint}
                            ${active && 'w-full text-primary-700 bg-primary-100'} 
                            ${!active && 'transform translate-y-9'}
                            ${mobileClassName} ${divClass} w-8 mb-1 rounded-md rounded-bl-none -top-3 `}>
                <InformationCircleIcon
                    id="tooltipIconMobile"
                    className={`${iconClasses}`} />
                {active && <span className="select-none">{content}</span>}
            </div>
            {/* Web */}
            <div className={`${inline ? 'pl-4' : '-mt-4'} relative hidden ${webBreakpoint}`}>
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    id="tooltipIconWeb"
                    onClick={toggleTip}
                    className={`${!inline && 'transform translate-y-[26px] -mt-6'} hidden md:inline  
                    ${iconClasses}`}/>
                    {/* Text display */}
                {active &&
                    <div style={{ filter: "drop-shadow(0 5px 4px rgba(0, 0, 0, 0.18))" }}
                        className={`transform ${inline ? '-translate-y-12' : '-translate-y-6'} z-40 relative`}>
                        {/* Triangle */}
                        < span className={`absolute left-7 top-7 border-primary-100 border-8 transform rotate-45`} />
                        {/* Wrapping */}
                        {children}
                        <div className={`${divClass} ${width ? width : 'w-60 xl:w-96'} absolute select-none rounded
                                        top-3 z-40 whitespace-pre-line
                                        ${backgroundColor} text-primary-700`}
                            onClick={toggleTip}>
                            {/* Content of Tooltip*/}
                            <div className="whitespace-pre-line">{content}</div>
                            {link &&
                                <div>
                                    <br />
                                    <a className="font-bold" href={linkRef}>{link}</a>
                                </div>}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default TooltipMobile;