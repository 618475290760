import { t } from 'i18next';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

function SortOptions({ currentSort, onChange }) {
    const { t } = useTranslation(['common']);
    currentSort = currentSort || 'oldest'

    const optionList = [
        { value: 'newest', text: t('Newest') },
        { value: 'oldest', text: t('Oldest') },
        { value: 'votes', text: t('Votes') },
        { value: 'alphabetical', text: t('Alphabetical') },
    ]

    return (
        <div id='sortOptions' className='relative text-sm select-none text-right cursor-pointer text-gray-600'>
            <select
                value={currentSort}
                onChange={(e) => onChange(e.target.value)}
                className="rounded-md border-gray-300 text-sm mt-1 shadow-sm pl-6"
            >
                {optionList.map((option) => (
                    <option key={option.value} value={option.value}>{option.text}</option>
                ))}
            </select>
            {/* Custom dropdown arrow */}
            <div className="absolute left-2 top-1/2 transform -translate-y-1/2 pt-1 pointer-events-none">
                <FontAwesomeIcon className='text-gray-600' icon={faSort}/>                
            </div>
        </div>
    );
}

SortOptions.propTypes = {
    currentSort: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export { SortOptions };