import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';

import { listProblems } from "actions/problems.list.actions";
import { getProjectById } from 'actions/project.get.actions';

import ProblemsList from './ProblemsList';
import EmptyState from 'components/EmptyStateCreateNew';
import sortList from 'utils/sort-list';
import GridHeader from 'components/GridHeader/indexGridHeader';

// Displays all Topics inside of a Cluster
function ProblemsListView({
  project,
  problems,
  getProjectById,
  listProblems,
}) {

  const { t } = useTranslation(['infobanners, foren', 'common']);
  const bannerText = t("banner-problems",
    `Define a problem, an open question or a topic request. Please be mindful to comply with antitrust and competition law and to disclose confidential information only carefully.
    Rate and provide feedback on the existing topics.`, { ns: 'infobanners' })
  const { projectId } = useParams();
  const [projectData, setProjectData] = useState({ users: [] });
  const [problemsList, setProblemsList] = useState([]);
  const [sortType, setSortType] = useState('oldest');
  const [searchInput, setSearchInput] = useState('')
  const [topicOwner, setTopicOwner] = useState({
    userId: null,
    firstName: null,
    lastName: null
  })
  const [topicInfo, setTopicInfo] = useState({ title: '', description: '' })
  const [typeSelectList, setTypeSelectList] = useState([
    { value: 'open', text: t('Open', { ns: 'common' }), checked: false },
    { value: 'implemented', text: t('Implemented', { ns: 'common' }), checked: false }])

  const [originalList, setOriginalList] = useState([])

  useEffect(() => {
    if (projectId) {
      getProjectById(projectId)
    }
  }, [projectId]);

  useEffect(() => {
    // getProjectById(projectId)
    if (projectId) {
      listProblems(projectId)
    }
  }, [projectId]);

  useEffect(() => {
    if (project) {
      setProjectData(
        {
          ...project
        }
      )
    }
  }, [project])

  // maps the tags into an itarable array with string items
  const mapTagsToText = (problems) => {
    return problems?.map((problem) => {
      const tags = problem.tags.map((tag) => tag.text);
      return { ...problem, tags: tags };
    });
  };

  useEffect(() => {
    if (problems?.length > 0) {
      // Map tags into words
      const problemsWithLists = mapTagsToText(problems);
      const savedSortType = localStorage.getItem('topicsSortOrder');
      let sortedList = []
      if (savedSortType) {
        setSortType(savedSortType)
        sortedList = (sortList(problemsWithLists, savedSortType))
      } else {
        sortedList = (sortList(problemsWithLists, sortType))
      }
      setOriginalList(preprocessCardContentList(sortedList, 'toSearch'))
      setProblemsList(sortedList)
    } else {
      setProblemsList([])
    }
  }, [problems]);

  useEffect(() => {
    if (project) {
      setTopicInfo({
        title: project.title,
        description: project.desc,
      })
      if (project.createdBy) {
        setTopicOwner({
          userId: project.createdBy.userId,
          firstName: project.createdBy.firstName,
          lastName: project.createdBy.lastName
        })
      }
    }
  }, [project]);

  // sends the text of the clicked tag to input/search
  const handleClick = (value) => {
    setSearchInput(value)
  }

  const handleSortChange = (newSortType) => {
    setSortType(newSortType)
    setProblemsList(sortList(problemsList, newSortType))
    localStorage.setItem('topicsSortOrder', newSortType);
  }

  const handleUpdateList = (updatedList) => {
    const processList = preprocessCardContentList(updatedList, 'toOriginal')
    setProblemsList(processList);
  }

  const resetList = () => {
    if (problems?.length > 0) {
      const problemsWithLists = mapTagsToText(problems);
      const sortedList = sortList(problemsWithLists, sortType)
      setProblemsList(sortedList)
    }
  }

  // just changes state of typeList, no search
  const handleTypeFilterChange = (checked, optionValue) => {
    setTypeSelectList((prevList) =>
      prevList.map((item) =>
        item.value === optionValue ? { ...item, checked } : item
      )
    );
  }


  // processes lists for minisearch string search
  const preprocessCardContentList = (list, direction) =>
    list?.map((item) => {
      if (direction === "toSearch") {
        return {
          ...item,
          isOpen: item.isOpen ? "open" : "implemented",
        };
      } else if (direction === "toOriginal") {
        return {
          ...item,
          isOpen: item.isOpen === "open",
        };
      }
      return item;
    });



  // props for filter component
  const filterProps = {
    originalList: originalList,
    searchFields: ["title", "statement", "tags", "role", "isOpen"],
    storeFields: ["title", "statement", "tags", "role", "isOpen", "userVotes", "contributorsCount", "commentsCount"],
    searchBoost: { title: 2, statement: 1 },
    updateList: handleUpdateList,
    searchTag: searchInput,
    resetList: resetList,
    handleSortChange: handleSortChange,
    currentSortType: sortType,
    typeFilter: true,
    typeSelectList: typeSelectList,
    handleTypeFilterChange,
  }

  return (
    <GridHeader
      bannerText={bannerText}
      topicInfo={topicInfo}
      owner={topicOwner}
      teamUserList={projectData?.users}
      filterProps={filterProps}
    >
      {problemsList?.length === 0 && projectId !== '00000000-0000-0000-0000-000000000000' &&
        <EmptyState
          icon={faFileCirclePlus}
          hoverText={t('fo-newTheme', 'Create a new theme', { ns: 'foren' })}
          descriptionText={t('fo-getStarted', 'Get started by creating a new theme.', { ns: 'foren' })}
          href={`/clusters/${projectId}/topics/new`}
          className={'mt-4'}
          color={'text-green-600'}
        />}
      {
        problemsList?.length > 0
        &&
        <ProblemsList
          problemsList={problemsList}
          clickTag={handleClick}
        />
      }
    </GridHeader>
  );

}

ProblemsListView.propTypes = {
  project: PropTypes.object,
  problems: PropTypes.array,
  listProblems: PropTypes.func.isRequired,
  getProjectById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  project: state.projectReducer.project,
  problems: state.problemReducer.problems
});

export default connect(mapStateToProps, { getProjectById, listProblems })(ProblemsListView);