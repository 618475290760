import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseItem } from 'utils/session-storage';
import sortList from 'utils/sort-list';
// import EmptyState from './EmptyState';
import ProjectsList from './ProjectsList';
import GridHeader from 'components/GridHeader/indexGridHeader';

function ProjectsListView({
  community,
  projects
}) {

  const { t } = useTranslation(['foren', 'infobanners']);
  const clusterInfo = {
    title: t('fo-pageTitleCluster', 'Content Clusters'),
    desc: t('fo-pageDescCluster', 'Content Clusters are predefined by your convener to provide a high-level structure.')
  }
  const bannerText = t("banner-projects",
    "Select a content cluster in order to define a problem, an open question or a topic request and to provide feedback on the existing ones.\nRate the clusters up or down depending on how relevant and/or useful they are to you.", { ns: 'infobanners' })

  const [projectsList, setProjectsList] = useState([]);
  const [communityData, setCommunityData] = useState({ users: [] });
  const [sortType, setSortType] = useState('oldest');

  useEffect(() => {
    if (parseItem('community')?.id && projects?.length > 0) {
      // hotfix: changing organisation
      let projectsMatchedCurrentCommunity = projects.filter(project => project.communityId === parseItem('community').id)

      if (projectsMatchedCurrentCommunity.length > 0) {
        // Initial Results are all problems
        const savedSortType = localStorage.getItem('projectsSortOrder');
        let sortedList = []
        if (savedSortType) {
          sortedList = sortList(projectsMatchedCurrentCommunity, savedSortType)
        } else {
          sortedList = sortList(projectsMatchedCurrentCommunity, sortType)
        }
        setProjectsList(sortedList)
      }
    } else {
      setProjectsList([])
    }
  }, [projects]);

  useEffect(() => {
    if (community) {
      setCommunityData(
        {
          ...community
        }
      )
    }
  }, [community])

  const handleSortChange = (newSortType) => {
    setSortType(newSortType)
    const sortedList = sortList(projectsList, newSortType)
    setProjectsList(sortedList)
    localStorage.setItem('projectsSortOrder', newSortType);
  }

  const handleUpdateList = (updatedList) => {
    setProjectsList(updatedList)
  }

  const resetList = () => {
    const filteredProjects = projects.filter(project => project.communityId === parseItem('community').id);
    const sortedList = sortList(filteredProjects, sortType);
    setProjectsList(sortedList);
  }

  const filterProps = {
    originalList: projectsList,
    searchFields: ["title", "desc", "role"],
    storeFields: ["title", "desc", "problems", "members", "role", "userVotes", "problems", "contributorsCount"],
    searchBoost: { title: 2, desc: 1 },
    updateList: handleUpdateList,
    resetList: resetList,
    handleSortChange: handleSortChange,
    currentSortType: sortType,

  }

  return (
    <GridHeader
      id='projectListView'
      bannerText={bannerText}
      topicInfo={clusterInfo}
      teamUserList={communityData?.users}
      filterProps={filterProps}
    >
      {parseItem('community') && <ProjectsList projects={projectsList} />}
    </GridHeader>
  );
}

ProjectsListView.propTypes = {
  community: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  community: state.communityReducer.currentCommunity,
  projects: state.projectReducer.projects
});

export default connect(mapStateToProps, null)(ProjectsListView);