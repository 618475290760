import PropTypes from 'prop-types'

function RangeDisplay({ id, value, min = 0, max, step = 0.1, onChange }) {

    const spanClass = 'text-sm text-gray-700'

    return (
        <div className='flex items-center z-0'>
            <div className='flex items-center space-x-2 w-48'>
                <span className={spanClass}>{min}</span>
                <input
                    id={id}
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={onChange}
                />
                <span className={spanClass}>{max}</span>
            </div>
            <input
                value={value}
                onChange={onChange}
                type='number'
                min={min}
                max={max}
                step={step}
                className='w-20 h-8 shadow-sm focus:ring-primary-500 focus:border-primary-500 block sm:text-sm border-gray-300 rounded-md'
            />
        </div>
    );
}

RangeDisplay.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
}

export default RangeDisplay;