import Input from "components/InputElement";
import { useState, useEffect } from "react";
import MiniSearch from "minisearch";
import PropTypes from 'prop-types';
import Dropdown from "components/Dropdown";
import { SortOptions } from "components/SortOptions";
import { useTranslation } from "react-i18next";

function FilterSection({ filterProps }) {
    const { t } = useTranslation(['common'])
    const {
        originalList,
        searchFields,
        storeFields,
        searchBoost,
        updateList,
        searchTag,
        resetList,
        handleSortChange,
        currentSortType,
        typeFilter,
        typeSelectList,
        handleTypeFilterChange,
    } = filterProps

    const [inputValue, setInputValue] = useState("");
    const [searchIndex, setSearchIndex] = useState(null);

    // initialize minisearch
    useEffect(() => {
        if (originalList?.length > 0) {
            const index = new MiniSearch({
                fields: searchFields || ['title'],
                storeFields: storeFields || ['title'],
                searchOptions: {
                    boost: searchBoost || { title: 1 },
                    prefix: true,
                    fuzzy: 0.25,
                },
            });
            index.addAll(originalList);
            setSearchIndex(index);
        }
    }, [originalList]);

    // handles search for tag clicks
    useEffect(() => {
        setInputValue(searchTag)
        handleSearch(searchTag)
    }, [searchTag])

    // filter type search (considers active textfilter)
    useEffect(() => {
        if (!originalList || !typeSelectList || !searchIndex) return;

        const activeFilters = typeSelectList
            .filter((filter) => filter.checked)
            .map((filter) => filter.value);

        let filteredResults = originalList;

        // filter for type
        if (activeFilters.length > 0) {
            filteredResults = filteredResults.filter((item) =>
                activeFilters.includes(item.isOpen)
            );
        }

        // filter for text if textfilter active
        if (inputValue.trim() !== "") {
            const textSearchResults = searchIndex.search(inputValue);
            filteredResults = filteredResults.filter((item) =>
                textSearchResults.some((result) => result.id === item.id)
            );
        }

        updateList(filteredResults);
    }, [typeSelectList, inputValue]);


    const handleSearch = (value) => {
        const newResults = searchIndex?.search(value, {});
        setInputValue(value);
        updateList(newResults)
        if (value === '' || null) {
            resetList()
        }
    };

    return (
        <div className="flex items-end space-x-2">
            <Input
                value={inputValue}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder={'Textfilter'}
            />
            {/* type filter component */}
            {typeFilter &&
                <Dropdown multiSelect={true} options={typeSelectList} onChange={handleTypeFilterChange} multiSelectLabel={t('Select Status')} />}
            {/* sorting */}
            <SortOptions onChange={handleSortChange} currentSort={currentSortType} />
        </div>
    );
}

FilterSection.propTypes = {
    filterProps: PropTypes.shape({
        originalList: PropTypes.array.isRequired,
        searchFields: PropTypes.arrayOf(PropTypes.string).isRequired,
        storeFields: PropTypes.array.isRequired,
        searchBoost: PropTypes.object,
        updateList: PropTypes.func.isRequired,
        searchTag: PropTypes.string,
        resetList: PropTypes.func.isRequired,
        handleSortChange: PropTypes.func.isRequired,
        currentSortType: PropTypes.string,
    }).isRequired,
};

export { FilterSection };