import HeaderText from "components/HeaderText";
import PropTypes from 'prop-types';

// displays title, description and owner 
function InfoSection({ owner, topicInfo }) {
    return (
        <div>
            <HeaderText>{topicInfo.title}</HeaderText>
            {owner && <a href={`/profiles/${owner.userId}`}>
                <p className='text-gray-500 text-xs hover:text-gray-700 hover:font-bold'>
                    {owner.firstName} {owner.lastName}
                </p>
            </a>}
            <div className='text-gray-600 mt-2'>{topicInfo.description}</div></div>
    );
}

InfoSection.propTypes = {
    owner: PropTypes.object,
    topicInfo: PropTypes.object
}

export default InfoSection;