import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

/**
 * General dropdown component
 * @param {Object[]} options - Options for the dropdown
 * @param {string|string[]} value - The current value (string for single, array for multi)
 * @param {function} onChange - Callback to handle changes
 * @param {boolean} multiSelect - Enable multi-select with checkboxes
 * @returns {JSX.Element} Dropdown component
 */
function Dropdown({ options, value, onChange, multiSelect = false, multiSelectLabel = 'Select options' }) {
    const [isOpen, setIsOpen] = useState(false); // For multi-select dropdown   visibility

    const optionClass = "border border-gray-300 rounded-md p-2 whitespace-nowrap text-sm select-none cursor-pointer text-gray-600 mt-1"
    const selectClass = "absolute bg-white border border-gray-300 rounded-md shadow-md w-40 p-2 z-50 text-sm text-gray-600"

    if (multiSelect) {
        return (
            <div className="relative">
                <button
                    className={optionClass}
                    onClick={() => setIsOpen((prev) => !prev)}
                    type="button"
                >
                    {value?.length > 0
                        ? `${value?.length} selected`
                        : multiSelectLabel}
                    <FontAwesomeIcon className='ml-4 w-3' icon={faChevronDown} />
                </button>
                {isOpen && (
                    <div className={selectClass}>
                        {options?.map((opt) => (
                            <label key={opt.value} className="block">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={opt.checked}
                                    onChange={(e) => onChange(e.target.checked, opt.value)}
                                />
                                {opt.text}
                            </label>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    // Default single-select dropdown
    return (
        <select
            className={"border p-1 px-3 rounded w-32 border-gray-400"}
            onChange={onChange}
            value={value}
        >
            {options?.map((opt) => (
                <option
                    key={opt.value}
                    value={opt.value}>
                    {opt.label}
                </option>
            ))}
        </select>
    );
}

export default Dropdown;
