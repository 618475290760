import Modal from 'components/Modal';
import TeamTable from 'components/TeamTable';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';

/**
 * @returns Icon for team management modal
 * this component can be extended for other administrative actions 
 */
function AdminSection({ teamUserList, showProjectRoles }) {

    return (
        <div>
            {/* team modal */}
            <Modal id='modalTeam'
                size='modal-xl'
                backdrop>
                <TeamTable
                    showOrganisationRoles
                    showCommunityRoles
                    showProjectRoles={showProjectRoles || false}
                    showLastLogin
                    users={teamUserList}
                />
            </Modal>
            {/* Icon for team modal */}
            <div className='group relative'
                data-bs-toggle='modal'
                data-bs-target={'#modalTeam'}>
                <div id='team' className=''>
                    <FontAwesomeIcon
                        id='team'
                        className='group-hover:text-gray-400 text-gray-300 mr-3 mt-2 h-6 w-6'
                        icon={faUserGroup} />
                </div>
            </div>
        </div>
    );
}

AdminSection.propTypes = {
    teamUserList: PropTypes.array.isRequired,
}

export { AdminSection };